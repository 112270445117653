<template>
<div class="margin-t-70">
    <Navbar/>
    <Partners/>
    <Footer/>
</div>
</template>

<script>
import Navbar from "@/components/navbar-stable";
import Footer from "@/components/footer";
import Partners from "@/components/partners";


export default {
    components : {
        Navbar,
        Footer,
        Partners
    }
}
</script>

<style>

</style>